/* DevExtreme Custom Styling for DataGrid and TreeList */

/* .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all) { */
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row {
  background-color: #222b36;
  /* opacity: 0.6;   */
}

/* .dx-widget, .dx-widget *, .dx-widget ::after, .dx-widget ::before, .dx-widget::after, .dx-widget::before { */
.dx-widget {
  background-color: #222b36;
}

/* .dx-datagrid-toolbar {
  background-color: #222B36;   
} */

.dx-treelist
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending),
.dx-treelist
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td.dx-treelist-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(
    .dx-treelist-select-all
  ),
.dx-treelist
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td:not(.dx-validation-pending):not(.dx-treelist-select-all) {
  background-color: #222b36;
}

/* .dx-datagrid .dx-row > td, .dx-datagrid .dx-row > tr > td{
  color: white;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    color: grey;
} */

.dx-treelist-content {
  background-color: #222b36;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
  background-color: #222b36;
}

.dx-toolbar-items-container {
  background-color: #222b36;
}

.dx-datagrid-rowsview .dx-datagrid-content {
  overflow-anchor: none;
  background-color: #222b36;
}

.dx-datagrid-borders > .dx-datagrid-header-panel {
  background-color: #222b36;
}

.dx-popup-flex-height .dx-popup-content {
  background-color: #222b36;
}

.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
  background-color: #222b36;
  border-bottom: 1px solid #515159;
}

.dx-popup-title.dx-toolbar {
  padding: 0 8px 0 16px;
  overflow: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #222b36;
}

.dx-popup-title.dx-toolbar {
  background-color: #222b36;
}

.dx-popup-draggable .dx-popup-title {
  background-color: #222b36;
}

.dx-popup-content {
  background-color: #222b36;
}

.dx-searchbox .dx-icon-search {
  visibility: hidden;
}

.dx-searchbox .dx-texteditor-input,
.dx-searchbox .dx-placeholder:before {
  padding-left: 8px;
  padding-right: 68px;
}

#tasks {
  max-height: 540px;
}

#tasks .dx-treelist-rowsview td {
  vertical-align: middle;
}

.img,
.name {
  display: inline-block;
  vertical-align: middle;
}

/*----End DevExtreme Custom Styling-----*/

/* Dashboard NavBar Styling */
.scanQrButton {
  /* color: white;
  border-color: white; */

  padding: 12px;
  border-radius: 20px;
  display: flex;
  width: 100%;
  border: 1.5px solid;
  font-weight: 400;
  position: relative;

  /* background-color: transparent;
  border: 1.5px solid;
  border-radius: 20px;
  display: flex;
  align-self: center;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  width: 100%;
  padding: 15px; */
}

.projectMenu {
  border: 1.5px solid;
  width: 100%;
  display: flex;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .scanQrButton {
    padding: 20px;
    margin: 10px;
  }

  .projectMenu {
    padding: 10px;
  }
}

/*End Dashboard NavBar Styling*/

/* Overview Container */
.overviewContainer {
  justify-content: center;
}

/* Styling for MUI Components */

.Link {
  cursor: pointer;
}

.typography {
  text-align: center;
}

.autoComplete {
  text-align: right;
  width: 400px;
}

/* For Spacing Box Headers in Project Add/Edit */
.projectFormBoxes {
  padding: 10px;
}

/* Add/Modify Crate Products/Container Crates Modal Textfield width */
.customTextfieldSelect {
  max-width: 400px;
}

/* Content/Add/Modify sections in tables */
.alignOptions {
  text-align: center;
  align-items: center;
}

/* For getting rid of weird highlited table rows */
.css-1gtveio-MuiTableRow-root.Mui-selected {
  background-color: #222b36;
}

.fileSizeSnackbar {
  background-color: #1f263e;
  color: white;
  font-weight: 400;
}

.menuItemOBL {
  color: #339900;
}

/* Upload attachments card container */
.uploadAttachmentsCard {
  min-height: 50px;
  padding: 15px;
}

/* Box that surrounds individual items in Products in crate, Crates in container*/
.displayContainerItemsBox {
  border: gray 1px solid;
  padding: 10px 10px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* Box that surrounds individual items in Products in crater*/
.displayCrateItemsBox {
  border: gray 1px solid;
  padding: 10px 10px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* Minimum width for read only forms */
.readOnlyCardContainer {
  min-width: 290px;
}

/* Style for all Grid containers for All Modals in the project */
.readOnlyModalContainer {
  padding-bottom: 20px;
}

/*---End MUI Components */

/* QR Code Styles for Container and Component Styling */
.QRCodeDisplay {
  width: 480px;
  height: 450px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 1100px) {
  .QRCodeDisplay {
    width: 400px;
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .QRCodeDisplay {
    width: 280px;
    height: 300px;
  }
}

.QRDialog {
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.QRCodeGenerator {
  display: none;
}

/* Button style for closing QRcode generator */
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}

/* ---End QR code styling--- */

/* Container/Grid for displaying attachments in Manufacturing module */
.attachmentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.1fr auto;
  grid-gap: 0.3rem;
  grid-template-areas: 'item-1 item-1 item-1 item-1';
}

/* Container/Grid for displaying attachments in Project Edit module */
.attachmentContainerProject {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 0.1fr auto;
  grid-gap: 0.5rem;
  grid-template-areas: 'item-1 item-1 ';
}

@media only screen and (max-width: 800px) {
  .attachmentContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1.2fr auto;
    grid-template-areas:
      'item-1'
      'item-1'
      'item-1'
      'item-1'
      'item-1'
      'item-1'
      'item-1'
      'item-1';
  }
}

#item-1 {
  grid-area: item-1;
}

.attachmentCellContainer {
  display: flex;
  flex-direction: column;
  columns: 2;
  padding: 3px;
}

.attachmentLink {
  color: gray;
  text-decoration: none;
  font-weight: 400;
}

/* Issue attachment styling */
.issueAttachmentsCard {
  min-height: 50px;
  padding: 15px;
}

.issueAttachmentMapContainer {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .issueAttachmentMapContainer {
    display: flex;
    flex-direction: column;
    columns: 2;
  }
}

/* -----End Attachments styling------ */

/* Styling for Products in Crates modal, Crates in Containers modal...etc */
/* Grid container for views */
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1.7fr auto;
  grid-template-areas:
    'content1 content1 content1 content1'
    'content2 content2 content2 content2';
  font-size: 14px;
  text-align: center;
  max-height: 700px;
  max-width: 1100px;
  margin: auto;
  padding-bottom: 20px;
}

.crate {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1.7fr auto;
  grid-template-areas:
    'content1 content1 content1 content1'
    'content2 content2 content2 content2';
  /*font-size: 14px;*/
  text-align: center;
  max-height: 700px;
  max-width: 1100px;
  margin: auto;
  padding-bottom: 20px;
}

.manufacturingModals {
  padding-bottom: 20px;
}

/* Media Query to change grid layout when screen is smaller than 800px (Mobile) */

@media only screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1.2fr 1.2fr 1fr;
    grid-template-areas:
      'content1'
      'content1'
      'content1'
      'content1'
      'content2'
      'content2'
      'content2'
      'content2';
  }
}

/* Content areas for grid container */
#content1 {
  grid-area: content1;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  padding: 20px;
}

#content2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
}

/* End Styling for Products in Crates modal, Crates in Containers modal...etc */

/* Styling for application version */
.siberVersionSpacer {
  /* color: gray; */
  margin-left: 6rem;
}

@media only screen and (max-width: 1300px) {
  .siberVersionSpacer {
    color: gray;
    margin-left: 1rem;
  }
}

/* End styling for application version  */

/* Google Maps Styles */
.googleMapContainer {
  height: 65vh;
  width: 100%;
}

.tracking-marker {
  display: flex;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1em;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 10px 7px 10px;
  background: #428569;
  margin-top: -43px;
  margin-left: -20px;
  position: absolute;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 0.2s;
}

.tracking-marker::before {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-width: 20px;
  border-right-color: #428569;
  margin-top: -10px;
  margin-left: -10px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  content: '';
  transition: border-right 0.2s;
}

.tracking-marker.js-active {
  background: #607d8b;
}

.tracking-marker.js-active::before {
  border-right-color: #607d8b;
}

/* -----End Google Maps styles---- */

/* Text */

.softwareVersionText {
  color: gray;
}

.copyrightText {
  color: gray;
}

/* Text for MUI tables */
.tableHeaderText {
  color: #a6aaae;
  text-align: center;
  align-items: center;
}

.tableContentText {
  text-align: center;
  align-items: center;
}

/* Text for Projects and ProjectContacs tables */
.projectTblHeaderText {
  color: #a6aaae;
  text-align: left;
  align-items: left;
}

.projectTblContextText {
  text-align: left;
  align-items: left;
}

/* Text for Users and User tables */
.userTblHeaderText {
  color: #a6aaae;
  text-align: left;
  align-items: left;
}

.userTblContextText {
  text-align: left;
  align-items: left;
}

/* change order calculator text*/
.RCOSfgCost {
  font-weight: 600;
  color: #f09712;
}

.RCOClientCost {
  font-weight: 600;
  color: #e74c3c;
}

.RCOAdjustmentAmount {
  font-weight: 600;
  color: green;
}

.changeCalculatorOrderBodyText {
  font-weight: 600;
  color: #65b16b;
}

/* end change order text */

/* Text for product grid messages*/

.doNotRequireGlass {
  font-weight: 600;
  color: rgb(245, 43, 43);
}

.doRequireGlass {
  font-weight: 600;
  color: rgb(36, 206, 87);
}

/* Styling for headers text in modal view e.g. Selected Crate: xxxxx */
.displayCrate {
  /* font-size: 20px;
  font-weight: 600; */
  text-align: center;
  padding: 8px;
}

.displayCrate2 {
  font-size: 20px;
  padding: 8px;
  font-weight: 600;
  /*color: #939EFF;*/
  text-align: center;
}

.displayContainer {
  font-size: 20px;
  padding: 8px;
  font-weight: 600;
  color: #939eff;
  text-align: center;
}

.changeProductStatusHeader {
  color: green;
  font-weight: 700;
}

.generalAttachmentHeader {
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
}

.qcAttachmentHeader {
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
}

/*----End Text--------*/

/* Buttons */

.addButton {
  text-align: left;
}

.cancelButton {
  float: right;
}

.overviewTimelineApplyButton {
  min-width: 209;
  min-height: 56;
}

.btnSiberStatus {
  background-color: transparent;
  border: 2.5px solid;
  border-radius: 0.7em;
  display: flex;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  width: 100%;
}

.btn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1.5px solid #e74c3c;
  border-radius: 0.6em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  margin: 2px;
  padding: 0.45em 0.45em;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.btn:hover,
.btn:focus {
  color: #fff;
  outline: 0;
}

.red {
  text-align: center;
  justify-content: center;
  width: 100%;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.red:hover {
  box-shadow: 0 0 40px 40px #e74c3c inset;
}

.defective {
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #e4002b;
  border-radius: 0.6em;
  color: #e4002b;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.defective:hover {
  box-shadow: 0 0 40px 40px #e4002b inset;
}

.blue {
  color: #7e96df;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #3969ef;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.blue:hover {
  box-shadow: 0 0 40px 40px #3969ef inset;
}

.green {
  color: #339900;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #339900;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.green:hover {
  box-shadow: 0 0 40px 40px rgb(110, 108, 108) inset;
}

.white {
  color: #ffffff;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #ffffff;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.white:hover {
  box-shadow: 0 0 40px 40px rgb(80, 77, 77) inset;
}

.cyan {
  color: #40bcd8;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #40bcd8;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.cyan:hover {
  box-shadow: 0 0 40px 40px rgb(110, 108, 108) inset;
}

.yellow {
  color: #ffcc00;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #ffcc00;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.yellow:hover {
  box-shadow: 0 0 40px 40px #ffcc00 inset;
}

.purple {
  color: #8f60fc;
  text-align: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid #8554f8;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.purple:hover {
  box-shadow: 0 0 40px 40px #7d46ff inset;
}

.button {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1.5px solid #e74c3c;
  border-radius: 0.6em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 13px;
  font-weight: 300;
  line-height: 1;
  margin: 2px;
  padding: 0.45em 0.45em;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.btn:hover,
.btn:focus {
  color: #fff;
  outline: 0;
}

/*-------End Button Styles*-------*/

/* Loaders */
.addtionalCommentLoader {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.generalLoadingSpinner1 {
  /* display: block;*/
  /*position: fixed;*/
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: table;
  z-index: 1031; /* High z-index so it is on top of the page */
  /* top: 50%;
  /* left: 50%;  or: left: 50%; */
  /* margin-top: -..px;  half of the elements height */
  /* margin-right: -..px;  half of the elements width */
}

.generalLoadingSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* This is for the new maps marker */
table.minimalistBlack {
  border: 3px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.minimalistBlack td,
table.minimalistBlack th {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table.minimalistBlack tbody td {
  font-size: 13px;
}
table.minimalistBlack thead {
  background: #cfcfcf;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  border-bottom: 3px solid #000000;
}
table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.minimalistBlack tfoot td {
  font-size: 14px;
}
/* This is for the new maps marker END */
